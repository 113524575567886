import React, { useEffect, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
import TitleBar from "../components/Layout/TitleBar";
import SEO from "../components/SEO";
import aboutImage from "../assets/images/about.jpg";

const AboutPage = () => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <Fragment>
      <SEO 
        title="About Us"
        description={'Cannabis Lawyer Toronto. Psychedelics Lawyer Toronto. Cannabis and Psychedelics Law Group LLP began as a collaboration between Paul Lewin and Jack Lloyd, two prominent Canadian lawyers in the realm of federal laws relating to controlled substances. As legalization and normalization of Cannabis and other controlled substances progressed, Paul and Jack were being asked by private individuals and small businesses to help navigate the turbulent waters of Canada’s emergent federal and provincial regulatory frameworks.'}
      />
      <div className="wrapper mt-5">
        <div className="main bg-grey">
          <Container>
            <TitleBar title="About Us" />
            <BreadCrumbs page="About Us" />
            <Card className="pl-4 pr-4 pb-4">
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title trajan pt-4 mb-2">Who we are?</h2>
                  <h5 className="description">
                    Cannabis and Psychedelics Law Group LLP began as a
                    collaboration between Paul Lewin and Jack Lloyd, two
                    prominent Canadian lawyers in the realm of federal laws
                    relating to controlled substances. As legalization and
                    normalization of Cannabis and other controlled substances
                    progressed, Paul and Jack were being asked by private
                    individuals and small businesses to help navigate the
                    turbulent waters of Canada’s emergent federal and provincial
                    regulatory frameworks.
                  </h5>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="7">
                  <p>
                    These files also required significant business, financial,
                    logistical and corporate commercial legal and regulatory
                    guidance for which additional team members were required.
                    Michael Starogiannis and Daniel Perlin joined the team and
                    Cannabis and Psychedelics Law Group LLP was formed with the
                    specific purpose of assisting companies and individuals in
                    navigating the complexities of Canada’s emergent cannabis
                    and psychedelics regulatory frameworks.
                  </p>
                  <p>
                    Cannabis and Psychedelics Law Group LLP is a
                    multidisciplinary team of professionals put together to
                    respond quickly to Canada’s rapidly evolving controlled
                    substance sector. While Paul Lewin and Jack Lloyd have deep
                    roots in the legal side of controlled substances, Daniel
                    Perlin offers decades of experience in corporate commercial
                    law and business organization. Michael Starogiannis is an
                    engineer and MBA with a wealth of experience in the
                    construction and finance sectors and assisting start-ups
                    with financing and business organization, as well as
                    conceptual design for cultivation and processing facilities.
                  </p>
                  <p>
                    Please don’t hesitate to contact the firm for advice on
                    federal cannabis licencing, provincial cannabis retail
                    licencing, and municipal planning and zoning guidance for
                    your cannabis or psychedelics business. Furthermore, should
                    your business require start up business advice, corporate
                    structure guidance, financial and business planning, as well
                    as planning, building and architectural guidance through the
                    process of getting your cannabis or psychedelics venture
                    started.
                  </p>
                  <p className="font-weight-bold">
                    For further information, please call and schedule a
                    consultation with the firm: 416-499-7945
                  </p>
                </Col>
                <Col md="5" className="text-center">
                  <img src={aboutImage} alt="Holding marijuana plant under a light" className="content-image" />
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutPage;
